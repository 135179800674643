import React, { FC } from 'react';
import highlightedImg from '../../static/images/boge-highlighted-image.png';
import {
    Container,
    HighlightedContent,
    HighlightedFlag,
    HighlightedFlagText,
    HighlightedImage,
    HighlightedImageWrapper,
    HighlightedText,
    HighlightedTitle,
    HiglightedTitleWrapper,
} from './HighlightedBannerStyle';

interface HighlightData {
    field_title: string;
    field_subtitle: string;
    field_text: any;
    field_use_diamon_star_icon: boolean;
    field_use_family_icon: boolean;
}

interface HiglightBannerInterface {
    data: HighlightData;
}

const HighlightedBanner: FC<HiglightBannerInterface> = ({
    data: {
        field_title: field_title,
        field_subtitle: field_subtitle,
        field_text: field_text,
        field_use_diamon_star_icon: field_use_diamon_star_icon,
        field_use_family_icon: field_use_family_icon,
    },
}) => (
    <Container>
        <HiglightedTitleWrapper>
            <HighlightedTitle>{field_title}</HighlightedTitle>
        </HiglightedTitleWrapper>
        <HighlightedContent>
            {field_use_diamon_star_icon && (
                <HighlightedImageWrapper>
                    <HighlightedImage src={highlightedImg} alt="Climate neutrality" />
                </HighlightedImageWrapper>
            )}
            {field_use_family_icon && (
                <HighlightedFlag>
                    <HighlightedFlagText>{field_subtitle ?? ''}</HighlightedFlagText>
                </HighlightedFlag>
            )}
            <HighlightedText
                dangerouslySetInnerHTML={{
                    __html: field_text,
                }}
            />
        </HighlightedContent>
    </Container>
);

export default HighlightedBanner;
